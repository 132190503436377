<template>
   <div class="col-sm-6 col-lg-12 col-xl-6 mt-1">
      <div class="form-check">
         <input class="form-check-input" type="checkbox" :id="'role'+ role.id" :checked="role.habilitado" v-model="role.habilitado" :disabled="moduloHabilitado">
         <label class="form-check-label ps-1" :for="'role'+ role.id" :class="moduloHabilitado ? '' : 'cursor-pointer'">
            <span class="limitador-1">{{ role.nome == null ? 'Sem nome' : role.nome }}</span>
         </label>
      </div>
   </div>
</template>

<script>

export default {
	name: 'CheckRole',
   props: ['role', 'moduloHabilitado']
}

</script>

<style scoped>

.form-check .form-check-input {
	transform: none !important;
}

.form-check-label {
	font-weight: 400 !important;
	font-size: 13.5px !important;
   text-transform: none !important;
}

</style>