<template>
   <div class="col-12 px-0 mb-1" ref="usuario">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-lg-block">
                  <img :src="usuario.avatar == null ? '' : usuario.avatar" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-md text-center text-md-start cursor-pointer" @click="editar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ usuario.nomeCompleto }}</h1>
                        <p class="font-10 mb-0 mb-lg-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ usuario.id }}</span>
                           <span class="ms-3">
                              <i class="far fa-envelope font-9 me-1"></i>
                              <span>{{ usuario.email == null || String(usuario.email).trim().length == 0 ? 'Sem email' : usuario.email }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col d-none d-lg-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-phone-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Telefone:</strong>
                           <span> {{ usuario.telefone == null || usuario.telefone.telefone == null || String(usuario.telefone.telefone).trim().length == 0 ? 'Sem telefone' : (usuario.telefone.ddd +' '+ usuario.telefone.telefone) }}</span>
                        </p>
                     </div>
                     <div class="col-6 d-none d-xl-block" v-if="tela != 'CONTATOS'">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-map-marker-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Endereço:</strong>
                           <span> {{ usuario.endereco == null || usuario.endereco.rua == null || String(usuario.endereco.rua).trim().length == 0 ? 'Sem endereço' : (usuario.endereco.rua +', '+ usuario.endereco.numero +' - '+ usuario.endereco.bairro) }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="tela == 'CONTATOS'">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-check"></i><small>Selecionar</small></span>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-else-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Editar usuários')">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-pen"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Usuario',
   props: ['usuario', 'index', 'tela'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      editar : function () {
         if (this.tela == 'CONTATOS') {
            this.$emit('select')
         } else if (this.dadosUsuario.usuarioPermissoes.includes('Configuração | Editar usuários')) {
            this.$emit('editar', this.usuario)
         }
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir usuário?',
            text: 'O usuário perderá acesso ao sistema.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoes/deleteUsuario',
                  params: {
                     id: this.usuario.id
                  }
               }).then(() => {
                  this.$refs.usuario.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Usuário excluído!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>