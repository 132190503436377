<template>
   <div class="col-12">
      <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col cursor-pointer" @click="loja.habilitado = !loja.habilitado">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ loja.nomeFantasia }}</h1>
                  <p class="font-10 mb-0 mb-xxl-1 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ loja.id }}</span>
                     <span class="ms-3">
                        <i class="far fa-store font-9 me-1"></i>
                        <span>{{ loja.numeroLoja == null || String(loja.numeroLoja).trim().length == 0 ? 'Sem número' : loja.numeroLoja }}</span>
                     </span>
                  </p>
                  <p class="font-12 mb-0 d-none d-xxl-inline">
                     <span class="limitador-1">
                        <i class="far fa-map-marker-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Endereço:</strong>
                        <span> {{ loja.endereco == null || loja.endereco.rua == null || String(loja.endereco.rua).trim().length == 0 ? 'Sem endereço' : (loja.endereco.rua +', '+ loja.endereco.numero +' - '+ loja.endereco.bairro) }}</span>
                     </span>
                  </p>
               </div>
               <div class="w-max-content text-center font-16">
                  <div class="form-check mb-0">
                     <input class="form-check-input" type="checkbox" :id="'loja'+ loja.id" :checked="loja.habilitado" v-model="loja.habilitado">
                     <label class="form-check-label" :for="'loja'+ loja.id"></label>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'CheckLoja',
   props: ['loja', 'index']
}

</script>